// For information on how to do something other than
// default webpack, see https://www.npmjs.com/package/bootstrap-webpack
'use strict'

// import 'bootstrap-webpack'
import '../../css/emig.scss'
import '../../css/juggle.scss';
import $ from "jquery";


$(document).ready(function() {
    if($('#juggle-announce-popup').is(':visible')){
        // This prevents any issues with forms on the underlying page
        // (e.g login form)
        // For example, Chrome trying to suggest values for form fields
        // which it plasters over the modal window!?
        // Simply giving focus to the button to visit the Juggle website.
        $('#juggle-website-link').focus();
    }
});
